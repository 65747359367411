import { APIClient } from "../../helpers/apiClient";
import { SET_SCHEDULED_MESSAGES, ADD_SCHEDULED_MESSAGE } from "./constants";

const apiClient = new APIClient();

export const setScheduledMessages = (allMessages) => {
  return {
    type: SET_SCHEDULED_MESSAGES,
    payload: allMessages,
  };
};

export const addScheduledMessage = (message) => {
  return {
    type: ADD_SCHEDULED_MESSAGE,
    payload: message,
  };
};

export const getScheduledMessages = () => async (dispatch, getState) => {
  try {
    const groupId = getState().Group.currentGroup?.id;
    const response = await apiClient.getFromMessageApi(`/scheduled-messages/pending`, {
      headers: { groupId },
    });
    dispatch(setScheduledMessages(response));
  } catch (error) {
    console.log("error", error.message);
  }
};

export const scheduleSessionMessage = (payload) => async (dispatch, getState) => {
  try {
    const msg = getState().Chat.message.text;
    if (!msg) throw new Error("Mensaje vacío o no válido");

    const groupId = getState().Group.currentGroup._id;
    const contactId = getState().Contact.activeContact._id;
    const { scheduledDate } = payload;

    const messageURL = `/scheduled-messages/${contactId}`;
    const msgBody = { scheduledDate, content: { text: msg, type: "text" } };
    const headers = { headers: { groupId } };
    const response = await apiClient.postToMessageApi(messageURL, msgBody, headers);
    dispatch(addScheduledMessage(response));
  } catch (error) {
    console.error("ERROR:", error?.message || error);
    throw new Error(error?.message || "Se produjo un error");
  }
};

export const scheduleTemplateMessage = (payload) => async (dispatch, getState) => {
  try {
    const groupId = getState().Group.currentGroup._id;
    const contactId = getState().Contact.activeContact._id;
    const { scheduledDate, templateId } = payload;

    const messageURL = `/scheduled-messages/${contactId}`;
    const msgBody = { scheduledDate, templateId };
    const headers = { headers: { groupId } };
    const response = await apiClient.postToMessageApi(messageURL, msgBody, headers);
    dispatch(addScheduledMessage(response));
  } catch (error) {
    console.error(error);
    throw error;
  }
};
