import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Chat from "./chat/reducers";
import Layout from "./layout/reducer";
import Templates from "./templates/reducer";
import Contact from "./contact/reducer";
import Group from "./group/reducer";
import Members from "./members/reducer";
import TemplatesMessage from "./templatesMessage/reducer";
import Tags from "./tag/reducer";
import Segments from "./segment/reducer";
import General from "./general/reducer";
import Campaigns from "./campaigns/reducer";
import Notes from "./notes/reducer";
import Counter from "./counter/reducer";
import ScheduledMessages from "./scheduledMessages/reducer";

export default combineReducers({
  Auth,
  Chat,
  Layout,
  Templates,
  Contact,
  Group,
  Members,
  TemplatesMessage,
  Tags,
  Segments,
  General,
  Campaigns,
  Notes,
  Counter,
  ScheduledMessages,
});
