import { SET_SCHEDULED_MESSAGES, ADD_SCHEDULED_MESSAGE } from "./constants";

const initialState = {
  scheduledMessages: [],
};

const ScheduledMessages = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCHEDULED_MESSAGES:
      return { ...state, scheduledMessages: action.payload };

    case ADD_SCHEDULED_MESSAGE:
      return {
        ...state,
        scheduledMessages: [action.payload, ...state.scheduledMessages],
      };

    default:
      return state;
  }
};

export default ScheduledMessages;
